<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3">
                <v-subheader>Custom</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox
                  background-color="white"
                  v-model="form.custom"
                  label="Custom"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.custom">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Percentage Custom</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.percentageCustom"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Customer</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.customerName"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="sequenceNumber"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="6" sm="6" md="3" lg="3" class="py-0">
                <v-subheader>PO Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  return-object
                  v-model="purchaseOrder"
                  :items="salePurchaseOrders"
                  :rules="[v => !!v || 'PO is required']"
                  :filter="filterObject"
                >
                  <template v-slot:selection="data">
                    {{ data.item.purchaseOrderNumber }}
                  </template>
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.purchaseOrderNumber }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.account.name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="3" lg="3" class="py-0">
                <v-btn color="success" @click="addPurchaseOrder" class="mr-2">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn color="primary" @click="viewTablePo">
                  <v-icon>
                    mdi-file-table-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Site <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="name"
                  item-value="id"
                  :items="listSite"
                  v-model="form.siteId"
                  @change="onChangeSite"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuInvoiceDate = false"
                    @change="onInputInvoiceDuration"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Term</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.invoiceDuration"
                  suffix="days"
                  @change="onInputInvoiceDuration"
                  :rules="[v => v >= 0 || 'Term must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Bill To<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  disabled
                  v-model="form.billTo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bill To Contact</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.billToContact"
                  item-text="name"
                  item-value="id"
                  :items="contacts"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Percentage Down Payment<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentageDownPayment"
                  :rules="percentageRules"
                  suffix="%"
                  @change="onInputPercentageDownPayment"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPN</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.percentagePpn"
                  suffix="%"
                  @change="onInputPercentagePpn"
                  :rules="[v => v >= 0 || 'PPN must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Rate</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.rate"
                  prefix="Rp."
                  :rules="[v => v >= 1 || 'Rate must more than equals 1']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-checkbox v-model="form.fp" label="Faktur Pajak Digunggung"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-checkbox
              v-model="form.backDate"
              label="Back Date Invoice"
              @change="onChangeBackDate"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="form.percentagePpn > 0 && !form.fp">
          <v-col cols="12" sm="12" md="12" lg="9" xl="9">
            <v-row>
              <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-row>
                  <v-col cols="3">
                    <v-select
                      dense
                      outlined
                      flat
                      background-color="white"
                      :items="arrayFronts"
                      v-model="twoDigit"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      dense
                      outlined
                      flat
                      background-color="white"
                      :items="arrayFrontNexts"
                      v-model="oneDigit"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" v-if="!form.backDate">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="serialNumber.number"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="form.backDate">
                    <v-autocomplete
                      outlined
                      flat
                      dense
                      background-color="white"
                      append-icon="mdi-magnify"
                      item-text="serialNumber"
                      return-object
                      v-model="tax"
                      :items="taxNumbers"
                      :rules="[v => !!v || 'Tax Number is required']"
                      @change="onChangeTax"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Already DP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ totalPercentage }}%</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Already DP (RP)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ formatPrice(totalRP) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Discount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span v-if="form.discount < 100 && form.discount >= 0">{{ form.discount }}%</span>
                <span v-if="form.discount > 100">{{ formatPrice(form.discount) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.discount="{ item }">
                          {{
                            item.discount > 100 ? formatPrice(item.discount) : `${item.discount}%`
                          }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Due Date</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.invoiceDueDate }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Currency</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.currency }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Percent Up To This Invoice</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.percentageUpTo }}%</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Site</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.site }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Sales</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.salesName }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Address</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.customerAddress }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Sub Total</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Invoice</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterInvoice) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">PPH</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.pphAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.taxAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterTax) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row>
          <v-col cols="12">
            <v-btn color="primary" @click="checkJournal">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn color="success" @click="refresh">
              <v-icon large>
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-sale-product
      :item="item"
      :index="index"
      :dialog="dialog"
      :accounts="accounts"
      @save="saveItem"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-sale-product>
    <dialog-view-table
      :dialog="dialogTable"
      :purchaseOrders="form.purchaseOrders"
      @close="close"
      @deleteItem="deleteItemTable"
      type="PO"
    ></dialog-view-table>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      currency="IDR"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogSaleProduct from "@/components/DialogSaleProduct";
import DialogViewTable from "@/components/DialogViewTable";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-sale-down-payment",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-sale-product": DialogSaleProduct,
    "dialog-view-table": DialogViewTable,
    DialogReviewJournal,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      invoiceNumber: "",
      invoiceDate: moment().format("yyyy-MM-DD"),
      invoiceDueDate: moment().format("yyyy-MM-DD"),
      invoiceDuration: 0,
      customerId: null,
      customerName: null,
      customerAddress: null,
      salesName: "",
      percentageDownPayment: 0,
      percentagePpn: 11,
      currency: "IDR",
      site: "",
      percentageUpTo: 0,
      discount: 0,
      billTo: "",
      remark: "",
      billToContact: null,
      taxInvoiceSerialNumber: "",
      materialDocumentNumber: "",
      totalAmount: 0,
      rate: 1,
      taxAmount: 0,
      pphAmount: 0,
      totalAmountAfterInvoice: 0,
      totalAmountAfterTax: 0,
      purchaseOrders: [],
      fp: false,
      taxId: null,
      products: [],
      status: "Outstanding",
      custom: false,
      percentageCustom: 0,
    },
    menuInvoiceDate: false,
    form: {},
    items: [],
    itemsTmp: [],
    customer: {},
    purchaseOrder: {},
    salePurchaseOrders: [],
    accounts: [],
    sites: [],
    listSite: [],
    dialog: false,
    dialogTable: false,
    dialogReview: false,
    item: {},
    index: -1,
    twoDigit: "01",
    oneDigit: 0,
    arrayFronts: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
    arrayFrontNexts: [0, 1],
    valid: true,
    journals: [],
    tax: {},
    totalPO: 0,
    accountNumber: "",
    fiscalMonths: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Product Detail",
        value: "partDesc",
        sortable: false,
        divider: true,
      },
      {
        text: "Qty",
        value: "quantity",
        sortable: false,
        divider: true,
      },
      {
        text: "U/M",
        value: "unitMeasure",
        sortable: false,
        divider: true,
      },
      {
        text: "U/Price",
        value: "unitPrice",
        sortable: false,
        divider: true,
      },
      {
        text: "Disc",
        value: "discount",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "Line Item",
        value: "lineItem",
        sortable: false,
        divider: true,
      },
      {
        text: "Part#",
        value: "partNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "PO#",
        value: "poNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "DO#",
        value: "doNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Mat Doc#",
        value: "materialDocumentNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account",
        value: "accountName",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    totalAmount() {
      let subtotal = 0;
      this.items.map(key => {
        subtotal = subtotal + key.total;
      });
      if (this.form.discount > 0 && this.form.discount <= 100) {
        return parseFloat((subtotal - subtotal * (this.form.discount / 100)).toFixed(0));
      } else {
        return parseFloat((subtotal - this.form.discount).toFixed(0));
      }
    },
    pphAmount() {
      let subtotal = 0;
      this.items.map(key => {
        subtotal = subtotal + key.pph23Amount;
      });
      return parseFloat(subtotal.toFixed(0));
    },
    totalPercentage() {
      let total = 0;
      this.totalPercentageDownPayment.forEach(x => {
        total += x.percentage;
      });
      return parseFloat(total.toFixed(2));
    },
    totalRP() {
      let total = 0;
      this.totalPercentageDownPayment.forEach(x => {
        total += x.totalAmount;
      });
      return parseFloat(total.toFixed(2));
    },
    percentageRules() {
      if (this.purchaseOrder && !this.form.custom) {
        const total = this.totalPercentage + this.purchaseOrder.percentageInvoice;
        return [
          v => !!v || "Percentage DP is required",
          v =>
            (v <= 100 - total && v > 0) ||
            "Percentage DP must be more than 1 and less than " + (100 - total),
        ];
      } else if (this.form.custom) {
        return [
          v => !!v || "Percentage DP is required",
          v => (v <= 100 && v > 0) || "Percentage DP must be more than 1 and less than 100",
        ];
      } else {
        const total = this.totalPercentage;
        return [
          v => !!v || "Percentage DP is required",
          v =>
            (v <= 100 - total && v > 0) ||
            "Percentage DP must be more than 1 and less than " + (100 - total),
        ];
      }
    },
    ...mapState("external", ["contacts"]),
    ...mapState("saleDownPayment", ["lastFormNumber", "totalPercentageDownPayment"]),
    ...mapState("saleInvoice", ["sequenceNumber"]),
    ...mapState("taxNumber", ["serialNumber", "taxNumbers"]),
  },

  watch: {
    totalAmount(val) {
      this.form.totalAmount = val;
      this.form.totalAmountAfterInvoice = parseFloat(
        (this.totalAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
      );
      this.form.taxAmount = Math.floor(
        parseFloat((this.form.totalAmountAfterInvoice * (this.form.percentagePpn / 100)).toFixed(2))
      );
      this.form.pphAmount = parseFloat(
        (this.pphAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
      );
      // this.form.totalAmountAfterTax =
      //   this.form.totalAmountAfterInvoice + this.form.taxAmount - this.form.pphAmount;
      this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
      if (this.form.custom) {
        this.form.percentageCustom = parseFloat(
          ((this.form.totalAmountAfterInvoice / this.totalPO) * 100).toFixed(2)
        );
        this.form.percentageUpTo = this.form.percentageCustom + this.totalPercentage;
      }
    },
    pphAmount(val) {
      this.form.pphAmount = val * (this.form.percentageDownPayment / 100);
    },
  },

  methods: {
    checkJournal() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.journals = [
          {
            accountNumber: this.accountNumber,
            accountName: `${this.form.customerName} IDR`,
            debit: this.form.totalAmountAfterTax * this.form.rate,
            credit: 0,
          },
          {
            accountNumber: "331.00000",
            accountName: `UANG MUKA PENJUALAN IDR`,
            debit: 0,
            credit: this.form.totalAmountAfterInvoice * this.form.rate,
          },
        ];
        if (this.form.taxAmount > 0) {
          this.journals.push({
            accountNumber: "320.00001",
            accountName: `HUTANG PPN KELUARAN`,
            debit: 0,
            credit: this.form.taxAmount * this.form.rate,
          });
        }
        // if (this.form.pphAmount > 0) {
        //   this.journals.unshift({
        //     accountName: `PAJAK PPH - 23`,
        //     debit: this.form.pphAmount,
        //     credit: 0,
        //   });
        // }
        this.journals.forEach(x => {
          this.$store
            .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
            .then(response => (x.pointers = response.data));
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.invoiceNumber = this.sequenceNumber;
        if (this.form.backDate) {
          this.form.taxInvoiceSerialNumber = this.form.fp
            ? ""
            : this.twoDigit + this.oneDigit + "." + this.tax.serialNumber;
          this.form.taxId = this.tax.id;
        } else {
          this.form.taxInvoiceSerialNumber = this.form.fp
            ? ""
            : this.twoDigit + this.oneDigit + "." + this.serialNumber.number;
          this.form.taxId = this.serialNumber.id;
        }
        this.form.products = this.items;

        // let totalDebit = this.form.totalAmountAfterTax + this.form.pphAmount;
        let totalDebit = this.form.totalAmountAfterTax;
        let totalCredit = this.form.totalAmountAfterInvoice + this.form.taxAmount;

        if (totalDebit - totalCredit === 0) {
          this.$store.commit("SET_LOADER", true);
          this.$store
            .dispatch("saleDownPayment/create", this.form)
            .then(() => {
              this.clear();
              this.$store.commit("SET_LOADER", false);
            })
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Credit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    onChangeSite(val) {
      if (val === 0) {
        this.items = this.itemsTmp;
      } else {
        this.items = this.itemsTmp.filter(x => x.siteId === this.form.siteId);
      }
    },
    async getSite(id) {
      await this.$store.dispatch("external/getSite", id).then(response => {
        response.data.unshift({ id: 0, name: "All" });
        this.listSite = response.data;
      });
    },
    async getPurchaseOrders() {
      await this.$store
        .dispatch("external/getSalePurchaseOrderAll", false)
        .then(response => (this.salePurchaseOrders = response.data));
    },
    async getAdditionalDataFromCustomerId(id) {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getSite(id), await this.$store.dispatch("external/getContact", id)])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    invoiceDueDate() {
      let dueDate = moment(this.form.invoiceDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
      return dueDate;
    },
    onInputInvoiceDuration(val) {
      if (this.form.currency !== "IDR") {
        this.getCurrency({ code: this.form.currency, date: val });
      } else {
        this.form.rate = 1;
      }
      this.form.invoiceDueDate = this.invoiceDueDate();
    },
    async getAR(id) {
      await this.$store
        .dispatch("chartOfAccount/getAR", id)
        .then(response => (this.accountNumber = response.data));
    },
    async addPurchaseOrder() {
      if (this.purchaseOrder === null) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "PO number belum dipilih",
        });
      } else {
        if (this.form.purchaseOrders.length > 0) {
          if (
            this.form.purchaseOrders[0].purchaseOrderNumber ===
            this.purchaseOrder.purchaseOrderNumber
          ) {
            if (this.form.purchaseOrders.indexOf(this.purchaseOrder) !== -1) {
              this.$store.dispatch("toast", {
                type: "error",
                message: "PO sudah dipakai pada form",
              });
            } else {
              this.form.purchaseOrders.push(this.purchaseOrder);
              this.items = this.items.concat(this.purchaseOrder.products);
              this.form.site = this.form.purchaseOrders.map(x => x.site).join(",");
              this.form.discount = this.purchaseOrder.discount;
              let total = 0;
              this.purchaseOrder.products.forEach(x => {
                total += x.total;
              });
              if (this.form.discount > 0 && this.form.discount <= 100) {
                this.totalPO =
                  parseFloat(total.toFixed(2)) -
                  parseFloat(total.toFixed(2)) * (this.form.discount / 100);
              } else {
                this.totalPO = parseFloat(total.toFixed(2));
              }
              this.getAR(this.purchaseOrder.account.id);
            }
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: "PO number tidak sama",
            });
          }
        } else {
          this.form.purchaseOrders.push(this.purchaseOrder);
          this.items = this.items.concat(this.purchaseOrder.products);
          // this.sites.push(this.purchaseOrder.site);
          // this.form.site = this.sites.join(",");
          this.form.salesName = this.purchaseOrder.user.name;
          this.form.customerAddress = this.purchaseOrder.address.street;
          this.form.currency = this.purchaseOrder.currency;
          if (this.purchaseOrder.currency !== "IDR") {
            this.getCurrency({ code: this.purchaseOrder.currency, date: this.form.invoiceDate });
          } else {
            this.form.rate = 1;
          }
          this.form.site = this.form.purchaseOrders.map(x => x.site).join(",");
          this.form.billToContact = this.purchaseOrder.contact.id;
          this.form.discount = this.purchaseOrder.discount;
          this.form.customerId = this.purchaseOrder.account.id;
          this.form.customerName = this.purchaseOrder.account.name;
          this.form.billTo = this.purchaseOrder.account.name;
          let total = 0;
          this.purchaseOrder.products.forEach(x => {
            total += x.total;
          });
          if (this.form.discount > 0 && this.form.discount <= 100) {
            this.totalPO =
              parseFloat(total.toFixed(2)) -
              parseFloat(total.toFixed(2)) * (this.form.discount / 100);
          } else {
            this.totalPO = parseFloat(total.toFixed(2));
          }
          this.getAR(this.purchaseOrder.account.id);
          this.getAdditionalDataFromCustomerId(this.purchaseOrder.account.id);
        }
      }
      await this.$store
        .dispatch("saleDownPayment/totalDownPayment", this.purchaseOrder.id)
        .then(() => {
          this.form.percentageDownPayment =
            100 - this.totalPercentage - this.purchaseOrder.percentageInvoice;
          this.onInputPercentageDownPayment();
        });
    },
    removePurchaseOrder(item) {
      this.form.site = this.form.purchaseOrders.map(x => x.site).join(",");
      const index = this.form.purchaseOrders.indexOf(item);
      if (index >= 0) this.form.purchaseOrders.splice(index, 1);
      this.form.discount = 0;
      this.removeSite(item);
      this.removeProduct(item);
    },
    removeSite(item) {
      const index = this.sites.indexOf(item.site);
      if (index >= 0) this.sites.splice(index, 1);
      this.form.site = this.sites.map(x => x.site).join(",");
    },
    removeProduct(item) {
      item.products.forEach(x => {
        const index = this.items.indexOf(x);
        if (index >= 0) this.items.splice(index, 1);
      });
    },
    onInputPercentageDownPayment() {
      this.form.totalAmountAfterInvoice = parseFloat(
        (this.totalAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
      );
      this.form.taxAmount = Math.floor(
        parseFloat((this.form.totalAmountAfterInvoice * (this.form.percentagePpn / 100)).toFixed(2))
      );
      this.form.pphAmount = parseFloat(
        (this.pphAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
      );
      // this.form.totalAmountAfterTax =
      //   this.form.totalAmountAfterInvoice + this.form.taxAmount - this.form.pphAmount;
      this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
      if (this.form.custom) {
        this.form.percentageCustom = parseFloat(
          ((this.form.totalAmountAfterInvoice / this.totalPO) * 100).toFixed(2)
        );
        this.form.percentageUpTo =
          this.form.percentageCustom + this.totalPercentage + this.purchaseOrder.percentageInvoice;
      } else {
        this.form.percentageUpTo =
          this.form.percentageDownPayment +
          this.totalPercentage +
          this.purchaseOrder.percentageInvoice;
      }
    },
    onInputPercentagePpn() {
      this.form.taxAmount = Math.floor(
        parseFloat((this.form.totalAmountAfterInvoice * (this.form.percentagePpn / 100)).toFixed(2))
      );
      // this.form.totalAmountAfterTax =
      //   this.form.totalAmountAfterInvoice + this.form.taxAmount - this.form.pphAmount;
      this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialogTable = false;
      this.dialogReview = false;
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    viewTablePo() {
      this.dialogTable = true;
    },
    deleteItemTable(item) {
      const index = this.form.purchaseOrders.map(x => x).indexOf(item);
      if (index > -1) {
        this.form.purchaseOrders.splice(index, 1);
        item.products.forEach(x => {
          const index = this.items.indexOf(x);
          if (index >= 0) this.items.splice(index, 1);
        });
        this.form.site = this.form.purchaseOrders.map(x => x.site).join(",");
      }
    },
    saveItem(data) {
      this.items[data.index] = data.item;
      this.close();
    },
    refresh() {
      this.form = Object.assign({}, this.defaultForm);
      this.form.purchaseOrders = [];
      this.purchaseOrder = {};
      this.customer = {};
      this.items = [];
    },
    clear() {
      this.customer = null;
      this.purchaseOrder = null;
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    filterObject(item, queryText) {
      return (
        item.purchaseOrderNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.account.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    onChangeTax(val) {
      this.form.taxId = val.id;
    },
    onChangeBackDate(val) {
      if (val) {
        this.tax = {};
      } else {
        this.form.taxId = this.serialNumber.id;
      }
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    async getCurrency(params) {
      this.$store
        .dispatch("currency/getRate", params)
        .then(response => (this.form.rate = response.data));
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("taxNumber/getNumber"),
        await this.$store.dispatch("saleDownPayment/getLastFormNumber"),
        await this.$store.dispatch("saleInvoice/getSequenceNumber"),
        await this.$store.dispatch("taxNumber/getAll", false),
        this.getAccounts(),
        this.getPurchaseOrders(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
  beforeMount() {
    this.form.percentagePpn = JSON.parse(localStorage.getItem("setting_tax") || []).find(
      x => x.name === "PPN"
    ).percentage;
  },
};
</script>

<style></style>
